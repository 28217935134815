import * as React from 'react';
import { graphql } from 'gatsby';
import BlogPostTemplate from '../templates/blog-post-template/blog-post-template';
import ContentPageTemplate from '../templates/content-page-template/content-page-template';

const MdxPage = ({ data }) => {

  const { pageType } = data.mdx.frontmatter;

  const templates = {
    post: <BlogPostTemplate data={data}/>,
    content: <ContentPageTemplate data={data} />,
  }

  return ( 
    <>
      {templates[pageType]}
    </>
  );
}

export const query = graphql`
  query ($id: String = "") {
    mdx(id: {eq: $id}) {
      frontmatter {
        pageType
        author
        title
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      body
      timeToRead
      parent {
        ... on File {
          modifiedTime(formatString: "MMMM D, YYYY")
        }
      }
    }
  }
`

export default MdxPage;

