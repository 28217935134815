import * as React from 'react';
import styled from "@emotion/styled";
import { Link } from 'gatsby';
import {MDXProvider} from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx';
import CustomLink from '../../components/custom-link/custom-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Layout from '../../components/layout/layout';
import CallToAction from '../../components/call-to-action/call-to-action';
import { Button } from '../../components/button/button';
import AuthorBox from '../../components/author-box/author-box';

// const shortcodes = {Link};

const PostContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
`
const PostDetails = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`

const PostDetail = styled.span`
  padding: 1rem;
  font-size: 1.25rem;
`

const ImageCredit = styled.p`
  text-align: center;
  font-size: 0.75rem;
  margin: 0;
  padding: 0.5rem;
`

const ImageCreditLink = styled.a`
  background: none;
  text-decoration: underline;
  &:hover {
    background: none;
    text-decoration: underline;
    text-shadow: ${({ theme }) => `0px 0px 10px ${theme.colors.primary}`};
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BlogPostTemplate = ({ data }) => {

  const { hero_image, title, hero_image_alt, hero_image_credit_link, hero_image_credit_text, author } = data.mdx.frontmatter;
  const { modifiedTime } = data.mdx.parent;
  const { body, timeToRead } = data.mdx;
  
  const image = getImage(hero_image);
  
  const imgStyle = {
    borderRadius: "1rem"
  }
  

  return (
    <Layout pageTitle={title} showNav={true}>
      <CallToAction />
        <article>
            <h1>{title}</h1>
            <PostContainer>
              <PostDetails>
                <PostDetail>By: {author}</PostDetail>
                <PostDetail>Updated: {modifiedTime}</PostDetail>
                <PostDetail>{timeToRead} min read</PostDetail>
              </PostDetails>
              <GatsbyImage 
                image={image} 
                alt={hero_image_alt} 
                style={imgStyle} 
                imgStyle={imgStyle} 
              />
              <ImageCredit>Photo by: {" "}
                <ImageCreditLink href={hero_image_credit_link} target="_blank" rel="noreferrer">
                  {hero_image_credit_text}
                </ImageCreditLink>
              </ImageCredit>
              {/* MDXProvider lets me use components in any .mdx file without importing each time - e.g. gatsby Link */}
              {/* <MDXProvider components={shortcodes}>  */}
              <MDXProvider components={{a: CustomLink}}> 
                <MDXRenderer>{body}</MDXRenderer>
              </MDXProvider>
            </PostContainer>
        </article>
        
        <Container>
          <Link to='/blog'>
            <Button>Back to the Posts</Button>
          </Link>
          
          <AuthorBox />
        </Container>
    </Layout>
  )
}

export default BlogPostTemplate