import styled from "@emotion/styled";

export const AuthorInfo = styled.div`
    max-width: 800px;
    margin: 2rem auto;
    border: ${({ theme }) => `1px solid ${theme.colors.lightestPurple}`};
    border-radius: 1rem;
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        padding: 1rem 0 0 ;
    }
`

export const AuthorPhoto = styled.div`
    border: ${({ theme }) => `1px solid ${theme.colors.lightestPurple}`};
    border-radius: 50%; 
`

export const AuthorDetails = styled.div`
    margin: 0 2rem;

    @media screen and (max-width: 768px) {
        text-align: center;
    }
`

export const AuthorName = styled.h3`
    margin: 0;
    color: ${({ theme }) => theme.colors.secondary};

    @media screen and (max-width: 768px) {
        margin-top: 1rem;
    }
`