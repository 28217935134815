import * as React from 'react';
import { Link } from 'gatsby';

const CustomLink = ({ children, href }) => {
    if (href.startsWith('/')) {
        // Use Gatsby's Link for internal site navigation to benefit from the preloading features.
        return <Link to={href}>{children}</Link>;
      }
      
      // Check if the link is for a section on the page because we don't want to add the attributes for the on page links.
      const onPage = href.startsWith('#');
      
      return (
        <a
          href={href}
          // Open the link in a new page
          target={onPage ? null : '_blank'}
          // Add noopener and noreferrer for security reasons
          // rel={onPage ? null : 'noopener noreferrer'}
          // noreferrer implies noopener
          rel={onPage ? null : 'noreferrer'}
        >
          {children}
        </a>
      );
}
 
export default CustomLink;