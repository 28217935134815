import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AuthorInfo, AuthorPhoto, AuthorDetails, AuthorName } from "./author-box.style";

const AuthorBox = () => {
    
    const imgStyle = {
        width: "150px",
        height: "150px",
        borderRadius: "50%",
    }

    return ( 
        <AuthorInfo>
            <AuthorPhoto>
                <StaticImage 
                    src="../../../static/img/profile-pic-nick.png" 
                    alt="nick, a bald white man with a goatee wearing a black sleeveless shirt" 
                    style={imgStyle}
                    imgStyle={imgStyle}
                />
            </AuthorPhoto>
            <AuthorDetails>
                <AuthorName>Nicholas Looney</AuthorName>
                <p>I own and operate this website, Calorie Planning. I replaced an unhealthy, addictive, and anxious lifestyle with proper nutrition, exercise, and meaningful goals. I have lost over 100 pounds and now I'm on a mission to help others improve their body and mind.</p>
            </AuthorDetails>
        </AuthorInfo>
     );
}
 
export default AuthorBox;