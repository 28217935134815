import * as React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from '@emotion/styled';

import Layout from '../../components/layout/layout';

const Container = styled.div`
    max-width: 1100px;
    margin: 0 auto;
`

const BodyContainer = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 0 1rem;
`

const ContentPageTemplate = ({ data }) => {

    const { title } = data.mdx.frontmatter;
    const { body } = data.mdx;
    
    return ( 
        <Layout pageTitle={title} showNav={true}>
            <Container>
                <h1>{title}</h1>
            </Container>
            <BodyContainer>
                <MDXRenderer>{body}</MDXRenderer>
            </BodyContainer>
        </Layout>
     );
}
 
export default ContentPageTemplate;